import $ from "jquery";

$(function() {
    const $allButtons = $("[data-opentooltip]");
    const $allTooltips = $("[data-tooltip]");
    $allButtons.each(function() {
        const $ttbutton = $(this); 
        const $tooltip = $("[data-tooltip="+$ttbutton.data("opentooltip")+"]");
        
        const popperInstance = Popper.createPopper($ttbutton.get(0), $tooltip.get(0), {
            placement: 'bottom-start',
            modifiers: [
                {
                    name: 'preventOverflow',
                    options: {
                    boundary: 'viewport', // Prevents popper from overflowing the viewport
                    padding: 7, // Optional: Adds padding between the popper and the viewport edge
                    },
                },
            ],
        });
        
        $ttbutton.on("click", function() {
            $tooltip.fadeToggle(200);
            $allTooltips.not($tooltip).fadeOut(200)
            popperInstance.update();
        });
    });

    $("main").on("click", function(ev) {
        const $clickedElement = $(ev.target);
        if($clickedElement.closest(".coc-tooltip").length > 0 || $clickedElement.closest("[data-opentooltip]").length > 0) {
            return;
        }
        $allTooltips.fadeOut(200);
    });
});
